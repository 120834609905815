import { store } from '@/store/store';
import { hist_preguntas_paciente } from '@/shared/dtos/hist_preguntas_paciente';
import {
    VuexModule,
    Mutation,
    getModule,
    Module,
    Action
} from 'vuex-module-decorators';
import { ssmHttpService } from '@/shared/services/http-service';
import { API } from '@/shared/api';

@Module({
    namespaced: true,
    name: 'hist_preguntas_pacienteModule',
    store,
    dynamic: true
})
class hist_preguntas_pacienteModule extends VuexModule {
    public hist_preguntas_pacientes: hist_preguntas_paciente[] = [];
    public hist_preguntas_paciente: hist_preguntas_paciente = new hist_preguntas_paciente();

    @Action({ commit: 'onGethist_preguntas_pacientes' })
    public async gethist_preguntas_pacientes(id: number) {
        return await ssmHttpService.get(API.hist_preguntas_paciente + '/?id_paciente=' + id);
    }

    /*@Action({ commit: 'onGethist_preguntas_paciente' })
    public async gethist_preguntas_paciente(id: any) {
        return await ssmHttpService.get(API.hist_preguntas_paciente + '/?id_paciente' + id);
    }
*/
    @Action
    public async guardarhist_preguntas_paciente(hist_preguntas_paciente: hist_preguntas_paciente[]) {
        /*toJson() no existe pero existe en la extends de BaseDto*/
        // #1 Mapping the array to an object...
        // #2 Converting the object to JSON...
        let json = JSON.stringify(hist_preguntas_paciente);
        await ssmHttpService.post(API.hist_preguntas_paciente, json);
        //  this.gethist_preguntas_pacientes();
    }

    @Action
    public async modificarhist_preguntas_paciente(hist_preguntas_paciente: hist_preguntas_paciente) {
        await ssmHttpService.put(API.hist_preguntas_paciente, hist_preguntas_paciente);
        //  this.gethist_preguntas_pacientes();
    }

    @Action
    public async eliminarhist_preguntas_paciente(hist_preguntas_paciente: hist_preguntas_paciente) {
        await ssmHttpService.delete(API.hist_preguntas_paciente + '/' + hist_preguntas_paciente.id, null, false);
        this.gethist_preguntas_pacientes(hist_preguntas_paciente.id_paciente);
    }

    @Mutation
    public onGethist_preguntas_pacientes(res: hist_preguntas_paciente[]) {
        this.hist_preguntas_pacientes = res ? res.map((x) => new hist_preguntas_paciente(x)) : [];
    }

    @Mutation
    public onGethist_preguntas_paciente(res: hist_preguntas_paciente) {
        this.hist_preguntas_paciente = new hist_preguntas_paciente(res);
    }

}
export default getModule(hist_preguntas_pacienteModule);